import { createGlobalStyle } from 'styled-components/macro';

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
  scroll-behavior: smooth;
}

*:focus {
  outline: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
  outline: none !important;
}

*, *:before, *:after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  border: 0;
  margin: 0;
  background-color: ${(p) => p.theme.colors.white};
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
  font-size: ${(p) => p.theme.fontSizes[2]};
  color: ${(p) => p.theme.colors.base};
}

html, body, #root, #root > div {
  height: 100%;
  position: relative;
}

h1 { letter-spacing: 0 !important; }
h2 { letter-spacing: 0 !important; }
h3 { letter-spacing: 0 !important; }
h4 { 
  letter-spacing: 0 !important; 
  font-size: ${(p) => p.theme.fontSizes[1]} !important;
  margin-bottom: ${(p) => p.theme.space[2]} !important;
}
h5 { letter-spacing: 0 !important; }
h6 { letter-spacing: 0 !important; }

p { font-size: ${(p) => p.theme.fontSizes[2]} !important; }

a {
  color: ${(p) => p.theme.colors.primary};
}

a:active, a:visited {
  color: ${(p) => p.theme.colors.primary};
}

a:hover {
  color: ${(p) => p.theme.colors.primaryDark};
}

.react-pdf__Document {
    height: 100%;
    width: 100%;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
  user-select: none;
}

.is-active-nav-link {
  > svg {
    color: white !important;
  }
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

@media (max-width: ${(p) => p.theme.breakpoints[1]}) {
  .LPMcontainer {
    display: none !important; 
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: ${(p) => p.theme.colors.secondary20};
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
`;

export default GlobalStyle;
