import { base64EncodeUnicode } from 'utils';
import { ACTION } from './actions';

export const initialState: State = {
  appSettings: {},
  socketConnectionErrors: {},
  disconnectionErrors: {},
  logoutPaused: false,
};

export type State = {
  appSettings: { [key: string]: any },
  socketConnectionErrors: { [key: string]: string },
  disconnectionErrors: { [key: string]: string },
  logoutPaused: boolean,
};

export default function applicationReducer(state: State, action: ACTION) {
  switch (action.type) {
    case 'SET_LOGOUT_PAUSED': {
      return { ...state, logoutPaused: action.payload };
    }
    case 'SET_APP_SETTINGS': {
      return {
        ...state,
        appSettings: action.payload,
      };
    }
    case 'SET_WEBSOCKET_DISCONNECTION_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return {
        ...state,
        disconnectionErrors: {
          ...state.disconnectionErrors,
          [id]: action.payload,
        },
      };
    }
    case 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const disconnectionErrors = {} as { [key: string]: string };
      Object.keys(state.disconnectionErrors).forEach((id) => {
        if (id !== payloadId) { disconnectionErrors[id] = state.disconnectionErrors[id]; }
      });
      return { ...state, disconnectionErrors };
    }
    case 'SET_WEBSOCKET_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return {
        ...state,
        socketConnectionErrors: {
          ...state.socketConnectionErrors,
          [id]: action.payload,
        },
      };
    }
    case 'REMOVE_WEBSOCKET_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const socketConnectionErrors = {} as { [key: string]: string };
      Object.keys(state.socketConnectionErrors).forEach((id) => {
        if (id !== payloadId) { socketConnectionErrors[id] = state.socketConnectionErrors[id]; }
      });
      return { ...state, socketConnectionErrors };
    }
    default:
      return state;
  }
}
