import { castObject } from 'utils/castObject';

export type AppSettingsType = { [key: string]: string } & {
  FEATURE_ENVELOPE_MAKER: boolean,
  REACT_APP_APPLICATION_INSIGHTS_KEY: string,
  REACT_APP_AUTH0_CLIENT_ID: string,
  REACT_APP_AUTH0_DOMAIN: string,
  REACT_APP_AUTH0_SESSION_INACTIVITY_TIMEOUT_IN_MILLISECOND: number,
  REACT_APP_AUTO_LOGOUT_TIMER_IN_MILLISECOND: number,
  REACT_APP_AUTO_LOGOUT_COUNTDOWN_DURATION_IN_MILLISECOND: number,
  REACT_APP_IN_DEVELOPMENT: boolean,
  REACT_APP_PUBLISHABLE_KEY: string,
  REACT_APP_SERVER_URL: string,
  REACT_APP_WIDGET_URL: string,
  REACT_APP_SIGNALR_ENDPOINT: string,
  REACT_APP_LOQATE_API_PASSWORD: string,
  REACT_APP_LOQATE_API_USERNAME: string,
  REACT_APP_LOQATE_API_URL: string,
  REACT_APP_MARKETING_URL: string,
};

export default (function ApplicationSettings() {
  return {
    ReadConfig: async (): Promise<AppSettingsType> => {
      const isProdMode = (process.env.REACT_APP_MODE === 'prod');
      try {
        const appSettingsDotJson = isProdMode
          ? '/appSettings.json'
          : '/appSettings.Development.json';
        const config = await fetch(appSettingsDotJson);
        const settings = await config.json();
        return castObject(settings) as AppSettingsType;
      } catch (error: any) {
        const err = new Error(error);
        throw err;
      }
    },
  };
}());
