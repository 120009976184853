/* eslint-disable import/prefer-default-export */
export const formatEnumToLabel = (value) => {
  if (!value) return '';
  const capitalized = value.charAt(0).toUpperCase() + value.slice(1);
  const keyToSentence = capitalized.replace(/([A-Z])/g, ' $1');
  return keyToSentence;
};

export const humanize = (input) => input
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
        .replace(/^([A-Z])/, (match) => match.toUpperCase()) // Ensure the first letter is uppercase
        .replace(/([A-Z])/g, (match) => ` ${match}`) // Add space before capital letters
        .trim();
