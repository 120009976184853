import { useState, useRef, PropsWithChildren } from 'react';
import { useApplication } from 'state/Application/ApplicationProvider';
import IdleTimer from 'react-idle-timer';
import LogoutTimer from 'components/LogoutTimer';
import { useAuth, SESSION_EXPIRY_STORAGE_KEY } from 'state/AuthProvider';

type Props = {
  isAuthenticated: boolean;
  isPaused: boolean;
};

const IdleLogout = ({ children, isAuthenticated, isPaused }: PropsWithChildren<Props>) => {
  const { state: { appSettings } } = useApplication();
  const [userIsIdle, setUserIsIdle] = useState(false);
  const idleTimer = useRef<IdleTimer>(null);
  const { logout } = useAuth();

  const resetTimer = () => {
    idleTimer.current?.reset();
    setUserIsIdle(false);
  };

  const handleOnIdle = () => {
    const sessionExpiry = window.sessionStorage.getItem(SESSION_EXPIRY_STORAGE_KEY);
    if (sessionExpiry && +sessionExpiry - +appSettings.REACT_APP_AUTO_LOGOUT_COUNTDOWN_DURATION_IN_MILLISECOND <= Date.now()) {
      logout({ returnTo: window.location.origin });
      return;
    }
    setUserIsIdle(true);
  };

  return (
    <>
      {isAuthenticated && !isPaused
        && (
          <IdleTimer
            stopOnIdle
            ref={idleTimer as any}
            element={document}
            onIdle={handleOnIdle}
            debounce={250}
            timeout={appSettings.REACT_APP_AUTO_LOGOUT_TIMER_IN_MILLISECOND}
          />
        )}
      {isAuthenticated && userIsIdle && !isPaused && <LogoutTimer onReset={() => resetTimer()} />}
      {children}
    </>
  );
};

export default IdleLogout;
