import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute, GuardFunction } from 'react-router-guards';
import Error from 'components/Error';
import { useAuth } from 'state/AuthProvider';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import StarterComponent from 'components/StarterComponent';
import FourOhFour from 'screens/FourOhFour';

const Manage = lazy(() => import('screens/manage'));
const Envelope = lazy(() => import('screens/envelope'));
const Auth = lazy(() => import('components/Auth'));
const OnBoarding = lazy(() => import('screens/onboarding'));
const Integration = lazy(() => import('screens/integration'));

const isNotConfigured: GuardFunction = (to, from, next) => {
  if (!to.meta.user?.userViewModel?.isConfigured || !to.meta.user?.userViewModel?.isOnboarded) { return next(); }
  return next.redirect('/manage');
};

export default function Router() {
  const { error, user } = useAuth();

  if (error) return <Error />;

  return (
    <Suspense fallback={<StarterComponent />}>
      <GuardProvider>
        <Switch>
          <GuardedRoute
            exact
            path="/"
            component={Auth}
          />
          <GuardedRoute path="/envelope" component={Envelope} />
          <GuardedRoute
            path="/manage"
            component={withAuthenticationRequired(Manage, { onRedirecting: () => <StarterComponent /> })}
          />
          <GuardedRoute
            path="/onboarding"
            component={withAuthenticationRequired(OnBoarding, { onRedirecting: () => <StarterComponent /> })}
            guards={[isNotConfigured]}
            meta={{ user }}
          />
          <GuardedRoute path="/sign-in" component={Auth} />
          <GuardedRoute path="/sign-up" component={Auth} />
          <GuardedRoute path="/integrate" component={Integration} />
          <Route path="*" component={FourOhFour} />
        </Switch>
      </GuardProvider>
    </Suspense>
  );
}
