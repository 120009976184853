import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import ReactDOM from 'react-dom';
import RootProvider from 'state/RootProvider';
import App from 'App';
import ApplicationSettings from 'actions/AppSettings';
import { StrictMode } from 'react';
import { applicationInsights } from 'logService';

ApplicationSettings
  .ReadConfig()
  .then((settings) => {
    if (settings.REACT_APP_APPLICATION_INSIGHTS_KEY) {
      applicationInsights.initialize(settings.REACT_APP_APPLICATION_INSIGHTS_KEY);
    }
    return settings;
  })
  .then((settings) => {
    ReactDOM.render(
      <StrictMode>
        <RootProvider settings={settings}>
          <App />
        </RootProvider>
      </StrictMode>,
      document.getElementById('root'),
    );
  });
