import {
  useState, useEffect, createContext, useContext, useCallback, ReactNode, useMemo,
} from 'react';
import { toast } from '@galilee/lilee';
import { IdToken, useAuth0 } from '@auth0/auth0-react';
import { getMe, changeAccount, resetAccount } from 'actions/Account';
import { useApplication } from 'state/Application/ApplicationProvider';
import { useHistory } from 'react-router-dom';
import { AccountViewModel } from 'types';
import logger from 'logService';

export type UserViewModel = {
  id: string,
  firstName: string,
  lastName: string,
  countryCodeId: number,
  mobileNumber: string,
  emailAddress: string,
  createdOn: Date,
  isOnboarded: boolean,
  isConfigured?: boolean,
  displayName: string,
  roles: string[],
  partnerId: string | null,
  hasAgreedTermsAndConditions: boolean,
  canEditPaymentDetails: boolean,
  canAccessBilling: boolean,
  canManageEnvelopes: boolean
};
export type UserAccountViewModel = {
  userViewModel: UserViewModel,
  account: AccountViewModel,
};
export enum Role {
  Client,
  Admin,
  AccountAdmin,
}

export const SESSION_EXPIRY_STORAGE_KEY = 'predicated_session_expiry';

const storePredictedSessionExpiry = (sessionTimeout: number, newAuthToken?: IdToken) => {
  if (!newAuthToken || !sessionTimeout) return;
  const newTokenIssueTime = (newAuthToken.iat || 0) * 1000 || Date.now();
  const predictedExpiry = newTokenIssueTime + sessionTimeout;
  const expiryInStorage = window.sessionStorage.getItem(SESSION_EXPIRY_STORAGE_KEY);
  if (!expiryInStorage || Number(expiryInStorage) !== predictedExpiry) {
    window.sessionStorage.setItem('predicated_session_expiry', predictedExpiry.toString());
  }
};

type ContextDefinition = ReturnType<typeof useAuthHook>;
const AuthContext = createContext<ContextDefinition | null>(null);

function useAuthHook() {
  const { state: { appSettings } } = useApplication();
  const [liveSignUser, setLiveSignUser] = useState<UserAccountViewModel>({} as UserAccountViewModel);
  const [ready, setReady] = useState(false);
  const [reload, setReload] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const auth0 = useAuth0();
  const history = useHistory();
  const {
    error,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = auth0;
  const [isLoadingUser, setIsLoadingUser] = useState(isLoading);

  const sessionTimeout = appSettings?.REACT_APP_AUTH0_SESSION_INACTIVITY_TIMEOUT_IN_MILLISECOND;

  const reloadUser = () => {
    setReload(true);
    setReloading(true);
  };

  const getAuthToken = useCallback(async (): Promise<string> => {
    let newAuthToken = await getIdTokenClaims();
    if (!ready && sessionTimeout) {
      storePredictedSessionExpiry(sessionTimeout, newAuthToken);
    }
    if (!newAuthToken) {
      await getAccessTokenSilently();
      newAuthToken = await getIdTokenClaims();
      storePredictedSessionExpiry(sessionTimeout, newAuthToken);
    }
    // eslint-disable-next-line no-underscore-dangle
    return newAuthToken?.__raw || '';
  }, [getIdTokenClaims, ready, sessionTimeout, getAccessTokenSilently]);

  const refreshUser = useCallback(async () => {
    const newAuthToken = await getAuthToken();
    const newUser = await getMe(newAuthToken);
    setLiveSignUser({ ...newUser });
  }, [getAuthToken]);

  const renewSession = useCallback(async () => {
    await getAccessTokenSilently({ ignoreCache: true });
    const newAuthToken = await getIdTokenClaims();
    storePredictedSessionExpiry(sessionTimeout, newAuthToken);
  }, [sessionTimeout, getAccessTokenSilently, getIdTokenClaims]);

  useEffect(() => {
    if (isLoading || !isAuthenticated || (ready && !reload)) return;

    async function initialiseState() {
      try {
        setReload(false);
        const newAuthToken = await getAuthToken();
        const newUser = await getMe(newAuthToken);
        setLiveSignUser({ ...newUser });
        setReady(true);
        setReloading(false);
        const hasAdminRole = newUser?.userViewModel?.roles?.includes(Role[Role.Admin]);
        setIsAdmin(hasAdminRole);
      } catch (err) {
        history.replace('/');
        toast.error("We couldn't load your user details right now. Please reload the page to try again.");
        logger.error(`Failed to load user details. Error: ${err}`);
      } finally {
        setIsLoadingUser(false);
      }
    }

    initialiseState();
  }, [getAuthToken, history, isAuthenticated, isLoading, ready, reload]);

  useEffect(() => {
    if (isLoading || (isAuthenticated && !liveSignUser.userViewModel)) {
      setIsLoadingUser(true);
      return;
    }
    setIsLoadingUser(false);
  }, [isLoading, isAuthenticated, liveSignUser]);

  const accountActions = {
    resetAccount: async () => {
      try {
        const newAuthToken = await getAuthToken();
        await resetAccount(newAuthToken);
        reloadUser();
        toast.success('Successfully reset your business account');
      } catch (err) {
        toast.error(`Failed to reset account: ${err}`);
      }
    },
    changeAccount: async (id: string) => {
      try {
        const newAuthToken = await getAuthToken();
        await changeAccount(newAuthToken, id);
        reloadUser();
        toast.success('Successfully changed your business account');
      } catch (err) {
        toast.error(`Failed to change account: ${err}`);
      }
    },
  };

  return useMemo(() => ({
    error,
    isAuthenticated,
    isLoading: isLoadingUser,
    loginWithRedirect,
    logout,
    ready,
    user: liveSignUser,
    getAuthToken,
    reloadUser,
    reloading,
    isAdmin,
    renewSession,
    refreshUser,
    accountActions,
  }), [error, getAuthToken, isAdmin, isAuthenticated, isLoadingUser, liveSignUser, loginWithRedirect, logout, ready, refreshUser, reloading, renewSession, accountActions]);
}

export const useAuth = () => {
  const contextValue = useContext(AuthContext);
  if (contextValue === null) throw Error('AuthContext has not been Provided!');
  return contextValue;
};

export default function AuthProvider({ children }: { children: ReactNode }) {
  const auth = useAuthHook();
  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  );
}
