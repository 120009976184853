import styled from 'styled-components/macro';
import {
  Flex, Button, Text,
} from '@galilee/lilee';

const Notification = styled(Flex)`
  position: fixed;
  bottom: 0;
  background-color: ${(p) => p.theme.colors.base60};
  color: white;
  padding: 15px;
  z-index: 100;
  width: 100%;
  justify-content: center;
  align-items: center;
  
`;

export default function NewVersionAvailable() {
  return (
    <Notification>
      <Text lg mr="4">A new version is available!</Text>
      <Button md color="primary" onClick={() => { window.location.reload(); }}>Update</Button>
    </Notification>
  );
}
