import {
  H1, H2, Button, Icon, mediaQueries,
} from '@galilee/lilee';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

const { greaterThan } = mediaQueries;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: ${(p) => p.theme.radii[2]};
  font-weight: bold;
  margin-top: 50px;
`;

const StyledH1 = styled(H1)`
  font-weight: 700;
  font-size: 150px;

  ${greaterThan[0]`
    font-size: 250px;
  `}
`;

const StyledH2 = styled(H2)`
  font-weight: 700;
  font-size: 30px;

  ${greaterThan[0]`
    font-size: 54px;
  `}
`;

const FourOhFour = () => {
  const history = useHistory();
  return (
    <Container>
      <StyledH1>404</StyledH1>
      <StyledH2>Page Not Found</StyledH2>
      <StyledButton
        onClick={() => { history.replace('/manage'); }}
        color="primary"
        rightIcon={<Icon name="ArrowRight" size="12px" mr={3} />}
        square
      >
        Back Home
      </StyledButton>
    </Container>
  );
};

export default FourOhFour;
