export type DemoTransactionPayload = {
  firstName: string,
  lastName: string,
  mobileNumber: string,
  countryCodeId: number,
  sendViaText: boolean,
  urlPath: string,
  baseUrl: string,
};

export type RegistrationViewModel = {
  id: string,
  displayName: string,
  companyName: string,
  companyAddress: string,
  emailAddress: string,
  abn: string,
  legalRepresentative: string,
  phoneNumber: string,
  createdOn: string,
  isConfigured: null | boolean,
};

export enum RegistrationStatus {
  NEW = 'NEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}
