import { ReactNode } from 'react';
import { Absolute } from '@galilee/lilee';
import styled from 'styled-components/macro';

const Overlay = styled(Absolute)`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;
export default function AnnouncementOverlay({ children }: { children: ReactNode }) {
  return children
    ? (
      <Overlay>
        {children}
      </Overlay>
    )
    : null;
}
