import { AppSettingsType } from 'actions/AppSettings';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import reducer, { initialState } from './reducers';

type ContextDefinition = ReturnType<typeof useApplicationHook>;
const ApplicationContext = createContext<ContextDefinition | null>(null);

const useApplicationHook = (settings: AppSettingsType) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SET_APP_SETTINGS', payload: settings });
  }, [settings]);

  const actions = {
    removeWebSocketError: (message: string) => dispatch({
      type: 'REMOVE_WEBSOCKET_ERROR',
      payload: message,
    }),
    setWebSocketError: (message: string) => dispatch({
      type: 'SET_WEBSOCKET_ERROR',
      payload: message,
    }),
    removeWebSocketDisconnectionError: (message: string) => dispatch({
      type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR',
      payload: message,
    }),
    setWebSocketDisconnectionError: (message: string) => dispatch({
      type: 'SET_WEBSOCKET_DISCONNECTION_ERROR',
      payload: message,
    }),
    pauseLogout: (isPaused: boolean) => {
      dispatch({ type: 'SET_LOGOUT_PAUSED', payload: isPaused });
    },
  };

  return {
    state,
    actions,
  };
};

export const useApplication = () => {
  const contextValue = useContext(ApplicationContext);
  if (contextValue === null) throw Error('ApplicationContext has not been Provided!');
  return contextValue;
};

type ApplicationProviderProps = {
  settings: AppSettingsType
};
export default function ApplicationProvider({ settings, children }: PropsWithChildren<ApplicationProviderProps>) {
  const value = useApplicationHook(settings);
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
}
