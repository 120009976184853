import { PropsWithChildren } from 'react';
import {
  CustomThemeProvider,
} from '@galilee/lilee';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { AppSettingsType } from 'actions/AppSettings';
import GlobalStyle from 'components/GlobalStyle';
import { History } from 'history';
import ApplicationProvider from '../Application/ApplicationProvider';
import AuthProvider from '../AuthProvider';
import livesignTheme from './livesignThemeOverrides';

const onRedirectCallback = (history: History) => (appState?: AppState) => {
  history?.push(appState?.returnTo || window.location.pathname);
};

function RootProvider({ settings, children }: Props) {
  const history = useHistory();

  const providerConfig: Auth0ProviderOptions = {
    domain: settings.REACT_APP_AUTH0_DOMAIN,
    clientId: settings.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}/manage`,
    onRedirectCallback: onRedirectCallback(history),
  };

  return (
    <ApplicationProvider settings={settings}>
      <Auth0Provider {...providerConfig}>
        <AuthProvider>
          <CustomThemeProvider theme={livesignTheme}>
            <GlobalStyle />
            {children}
          </CustomThemeProvider>
        </AuthProvider>
      </Auth0Provider>
    </ApplicationProvider>
  );
}

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';

type Props = PropsWithChildren<{
  settings: AppSettingsType,
}>;
export default function BrowserRouterProvider(props: Props) {
  return (
    <BrowserRouter basename={baseUrl}>
      <RootProvider {...props} />
    </BrowserRouter>
  );
}
