import { UserAccountViewModel, UserViewModel } from 'state/AuthProvider';
import { AddUserViewModel, UpdateAccountViewModel, UpdateUserViewModel } from 'state/Manage/types';
import { httpRequest } from 'utils';
import { PaymentOption } from 'types/constants';
import { format } from 'date-fns';

export const setDefaultPayeeAction = async (paymentOption: PaymentOption, token: string) => {
  const response = await httpRequest(`Account/set-payment-option?paymentOption=${paymentOption}`, 'patch', { Authorization: `Bearer ${token}` });
  return response;
};

export const setUserAction = async (userDetails: Partial<UserViewModel>, token: string) => {
  const response = await httpRequest('User', 'post', { Authorization: `Bearer ${token}` }, userDetails);
  return response;
};

export const createAccountAction = async (payload: { user: UpdateUserViewModel, account: UpdateAccountViewModel }, token: string) => {
  const response = await httpRequest('Account', 'post', { Authorization: `Bearer ${token}` }, payload);
  return response;
};

export const setAccountAction = async (accountDetails: UpdateAccountViewModel, token: string) => {
  const response = await httpRequest('Account', 'patch', { Authorization: `Bearer ${token}` }, accountDetails);
  return response;
};

export const setOnBoarding = async (onboardReference: string | null, token: string) => {
  const response = await httpRequest('Identity/onboard', 'post', { Authorization: `Bearer ${token}` }, { onboardReference });
  return response;
};

export const getMe = async (token: string): Promise<UserAccountViewModel> => {
  const response = await httpRequest('Identity', 'post', { Authorization: `Bearer ${token}` });
  return response;
};

export const changeAccount = async (token: string, id: string) => {
  const response = await httpRequest(`Identity/changeadminaccount/${id}`, 'post', { Authorization: `Bearer ${token}` });
  return response;
};

export const resetAccount = async (token: string) => {
  const response = await httpRequest('Identity/resetadminaccount', 'post', { Authorization: `Bearer ${token}` });
  return response;
};

export const fetchEmailVerifiedAction = async (token: string) => {
  if (!token) return null;
  return httpRequest('Identity/emailverified', 'get', { Authorization: `Bearer ${token}` });
};

export const resendEmailVerificationAction = async (token: string) => {
  if (!token) return null;
  return httpRequest('Identity/resendverification', 'post', { Authorization: `Bearer ${token}` });
};

export const fetchUsers = async (token: string): Promise<AddUserViewModel[]> => {
  const response = await httpRequest('Account/users', 'get', { Authorization: `Bearer ${token}` });
  return response;
};

export const saveUser = async (user: AddUserViewModel, token: string): Promise<string> => {
  if (!user.id) {
    const response = await httpRequest('Account/users', 'post', { Authorization: `Bearer ${token}` }, user);
    return response;
  }
  const response = await httpRequest(`Account/users/${user.id}`, 'put', { Authorization: `Bearer ${token}` }, user);
  return response;
};

export const deleteUser = async (userId: string, token: string): Promise<AddUserViewModel[]> => {
  const response = await httpRequest(`Account/users/${userId}`, 'delete', { Authorization: `Bearer ${token}` });
  return response;
};

export const exportBilling = async (startDate: Date, endDate: Date, token: string): Promise<{ fileName: string, data: any }> => {
  const response = await httpRequest('Account/billing-report', 'post', { Authorization: `Bearer ${token}` }, { startDate: format(startDate, 'yyyy-MM-dd'), endDate: format(endDate, 'yyyy-MM-dd') });
  return response;
};
