export const isNumeric = (n: any) => (n === null ? false : (!isNaN(n) && isFinite(n)));

export function castObject(obj: { [key: string]: any }, ignoreProps: string[] = []) {
  return Object.entries(obj).reduce((acc, curr) => {
    const [k, v] = curr;
    if (ignoreProps.length && ignoreProps.includes(k)) {
      acc[k] = v;
      return acc;
    }
    if (v === 'true') {
      acc[k] = true;
      return acc;
    }
    if (v === 'false') {
      acc[k] = false;
      return acc;
    }
    if (isNumeric(v)) {
      acc[k] = +v;
      return acc;
    }
    acc[k] = v;
    return acc;
  }, {} as { [key: string]: any });
}
