import { useState } from 'react';
import { ToastNotifications } from '@galilee/lilee';
import ConnectionError from 'components/ConnectionError';
import { useApplication } from 'state/Application/ApplicationProvider';
import { useAuth } from 'state/AuthProvider';
import AppRouter from 'Router';
import IdleLogout from 'components/IdleLogout';
import NewVersionAvailable from 'components/NewVersionAvailable';
import Announcements from 'components/Announcements';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const App = () => {
  const { state } = useApplication();
  const { isAuthenticated } = useAuth();
  const [hasNewVersion, setHasNewVersion] = useState(false);

  function onNewVersionAvailable() {
    setHasNewVersion(true);
  }
  serviceWorkerRegistration.register({
    onUpdate: onNewVersionAvailable,
  });

  return (
    <>
      <Announcements />
      <ToastNotifications autoCloseDuration={5} />
      <IdleLogout isAuthenticated={isAuthenticated} isPaused={state.logoutPaused}>
        {hasNewVersion && <NewVersionAvailable />}
        <ConnectionError disconnectionErrors={state.disconnectionErrors} socketConnectionErrors={state.socketConnectionErrors} />
        <AppRouter />
      </IdleLogout>
    </>
  );
};

App.displayName = App.name;

export default App;
