export * from './dateFormatter';
export * from './stringFormatter';
export * from './queryBuilder';
export * from './iconStatusConverters';
export * from './httpRequest';

export const base64EncodeUnicode = (str) => btoa(
  encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)),
);

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = blob;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};
