import { SeverityLevel } from '@microsoft/applicationinsights-common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

type CustomProperties = { [key: string]: any };

let appInsights: ApplicationInsights;

const createTelemetryService = () => {
  const initialize = (key: string) => {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        enableAutoRouteTracking: true,
        disableFetchTracking: false,
        enableAjaxErrorStatusText: true,
        enableUnhandledPromiseRejectionTracking: true,
      },
    });
    appInsights.loadAppInsights();
    appInsights.addTelemetryInitializer((telemetry) => {
      // to avoid redundant page view telemetry being sent to Application Insights when a component re-renders.
      if (telemetry?.baseType === 'PageviewData' && telemetry?.baseData?.refUri === telemetry?.baseData?.uri) { return false; }
      if (telemetry?.baseData?.type === 'Fetch' && telemetry?.baseData?.success) { return false; }
      return true;
    });
  };

  return { appInsights, initialize };
};

const info = (message: string, customProperties?: CustomProperties) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Information,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

const warning = (message: string, customProperties?: CustomProperties) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Warning,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

const error = (message: string, customProperties?: CustomProperties) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Error,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

type CriticalErrorPayload = {
  message: string,
  stacktrace: string,
  token: string,
};
const critical = (message: string, customProperties: CriticalErrorPayload & CustomProperties) => {
  const telemetry = {
    message,
    severityLevel: SeverityLevel.Critical,
  };

  appInsights?.trackTrace(telemetry, customProperties);
};

const event = (name: string, customProperties?: CustomProperties) => {
  const telemetry = {
    name,
    properties: customProperties,
  };
  appInsights?.trackEvent(telemetry);
};

const logger = {
  info,
  warning,
  error,
  critical,
  event,
};

export const applicationInsights = createTelemetryService();
export default logger;
