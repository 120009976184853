export const isValidDate = (date: any) => date instanceof Date
  && !Number.isNaN(Number.parseInt(date?.getTime()?.toString(), 10));

type HumanReadableFormatOptions = {
  suffix?: string;
  prefix?: string;
  depth?: number;
  offset?: { seconds: number; label: string; }
};
export const humanReadableFormat = (date: Date, options: HumanReadableFormatOptions): string => {
  if (!isValidDate(date)) return 'Invalid date';

  const SECONDS = {
    IN_A_YEAR: 31536000,
    IN_A_MONTH: 2592000,
    IN_A_DAY: 86400,
    IN_AN_HOUR: 3600,
    IN_A_MINUTE: 60,
  };

  const {
    suffix = '', prefix = '', depth = 1, offset = { seconds: 60, label: 'Just now' },
  } = options;

  let remainingSeconds = Math.floor((+new Date() - +date) / 1000);

  const years = Math.floor(remainingSeconds / SECONDS.IN_A_YEAR);
  remainingSeconds -= years * SECONDS.IN_A_YEAR;

  const months = Math.floor(remainingSeconds / SECONDS.IN_A_MONTH);
  remainingSeconds -= months * SECONDS.IN_A_MONTH;

  const days = Math.floor(remainingSeconds / SECONDS.IN_A_DAY);
  remainingSeconds -= days * SECONDS.IN_A_DAY;

  const hours = Math.floor(remainingSeconds / SECONDS.IN_AN_HOUR);
  remainingSeconds -= hours * SECONDS.IN_AN_HOUR;

  const minutes = Math.floor(remainingSeconds / SECONDS.IN_A_MINUTE);
  remainingSeconds -= minutes * SECONDS.IN_A_MINUTE;

  if (offset.seconds) {
    const dateWeight = years * SECONDS.IN_A_YEAR + months * SECONDS.IN_A_MONTH + days * SECONDS.IN_A_DAY + minutes * SECONDS.IN_A_MINUTE;
    if (dateWeight <= offset.seconds) return offset.label;
  }

  const segments = {
    year: years,
    month: months,
    day: days,
    hour: hours,
    minute: minutes,
  };

  const humanFormat = Object.entries(segments)
    .filter(([, value]) => value)
    .slice(0, depth)
    .reduce((acc, [key, value]) => {
      const formatPlural = (singularStr: string, number: number) => (number && number > 1 ? `${singularStr}s` : `${singularStr}`);
      const text = `${acc} ${value} ${formatPlural(key, value)}`;
      return text.trim();
    }, '');

  return `${prefix} ${humanFormat} ${suffix}`.trim();
};

export const timeZoneAbbreviated = () => {
  const dateInfo = new Date().toString().match(/\((.+)\)/);
  if (!dateInfo) return '';

  const { 1: tz } = dateInfo;

  if (!tz.includes(' ')) return tz;

  return tz
    .split(' ')
    .map(([first]: string) => first)
    .join('');
};

export const localDateToISO = (date: Date) => {
  const d = new Date(date);
  d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
  return d.toISOString().slice(0, 10);
};
