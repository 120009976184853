const queryStringBuilder = (key, value, initial = '') => {
  if (value === undefined || value === '') return initial;
  const segment = (Array.isArray(value))
    ? value.map((val) => queryStringBuilder(key, val, '')).join('&')
    : `${key}=${encodeURIComponent(value)}`;

  if (!segment) return initial;

  const newQuery = (initial !== '') ? `${initial}&${segment}` : segment;
  return newQuery;
};

// eslint-disable-next-line import/prefer-default-export
export const queryBuilder = (queryObject) => Object.entries(queryObject || {})
  .reduce((acc, [key, value]) => queryStringBuilder(key, value, acc), '');
