import { useAuth } from 'state/AuthProvider';
import { useApplication } from 'state/Application/ApplicationProvider';
import styled, { DefaultTheme } from 'styled-components/macro';
import Countdown from 'react-countdown';
import {
  Fixed, H2, Text, Button, Flex, mediaQueries,
} from '@galilee/lilee';

const { greaterThan } = mediaQueries;

const LogoutWrapper = styled(Fixed)`
    width: 100%;
    box-shadow: ${(p) => p.theme.shadows.xl};
    z-index: 9999;
    ${greaterThan[0]`
      left: 50%;
      transform: translateX(-50%);
      margin-top: ${(p: { theme: DefaultTheme }) => p.theme.space[8]};
      max-width: ${(p: { theme: DefaultTheme }) => p.theme.threshold.contentMaxWidth};
      border-radius: ${(p: { theme: DefaultTheme }) => p.theme.radii[3]};
    `};
`;

const LogoutContainer = styled(Flex)`
  flex-direction: column;
  margin: auto;
  gap: ${(p) => p.theme.space[4]};
  ${greaterThan[0]`
    border-radius: ${(p: { theme: DefaultTheme }) => p.theme.radii[3]};
  `};
`;

LogoutContainer.defaultProps = {
  bg: 'tertiary',
  color: 'base',
  px: [6, 7],
  py: 7,
};

const ButtonWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: ${(p: { theme: DefaultTheme }) => p.theme.space[5]};
  margin-bottom:${(p: { theme: DefaultTheme }) => p.theme.space[3]};
  ${greaterThan[0]`
    margin-top: 0;
  `}
`;

const Timer = styled(Text)`
  display: block;
  font-weight: bold;
  ${greaterThan[0]`
    display:inline;
  `}
`;

type Props = {
  onReset: () => void;
};

type CountdownTimerProps = {
  minutes: number;
  seconds: number;
};

export default function LogoutTimer({ onReset }: Props) {
  const { renewSession, logout } = useAuth();
  const { state: { appSettings } } = useApplication();
  const handleReset = async () => {
    await renewSession();
    onReset();
  };

  const CountdownTimer = ({ minutes, seconds }: CountdownTimerProps) => {
    const minuteString = minutes > 0 ? `${minutes} minute${minutes === 1 ? '' : 's'} and ` : '';
    const secondString = `${seconds} second${seconds === 1 ? '' : 's'} `;
    return (
      <Timer>
        {`${minuteString}${secondString}`}
      </Timer>
    );
  };

  return (
    <LogoutWrapper>
      <LogoutContainer>
        <Flex flexDirection="column" alignItems="flex-start">
          <H2 mb="4">Are you still there?</H2>
          <Text fontSize="3">
            You&apos;ve not taken any action on the page recently. You will be logged out of livesign in&nbsp;:&nbsp;
            <Countdown
              date={Date.now() + appSettings.REACT_APP_AUTO_LOGOUT_COUNTDOWN_DURATION_IN_MILLISECOND}
              onComplete={() => logout({ returnTo: window.location.origin })}
              renderer={CountdownTimer}
            />
          </Text>
        </Flex>
        <ButtonWrapper>
          <Button noBorder color="secondary" onClick={handleReset}>Continue</Button>
        </ButtonWrapper>
      </LogoutContainer>
    </LogoutWrapper>
  );
}
