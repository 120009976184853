import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { layout } from 'styled-system';
import { Box, mediaQueries } from '@galilee/lilee';

const { greaterThan } = mediaQueries;

const centerStyle = ({ center, theme, maxWidth }) => {
  if (!center) {
    return `
      flex: 1;
      ${layout}
    `;
  }
  return `
  width: 100%;
  padding: 0;
  max-width: ${maxWidth};
  ${layout}
  
  ${greaterThan[0]`
    padding: ${theme.space[4]} 0;
    width: 95%;
  `}

  ${greaterThan[1]`
    margin: auto;
    width: 90%;
    padding: ${theme.space[7]} 0;
  `}
`;
};

const Container = styled(Box)`
  ${centerStyle}
`;

Container.defaultProps = {
  children: undefined,
  maxWidth: '1100px',
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Container;
