import Lottie from 'lottie-react-web';
import { useEffect, useState } from 'react';

export default function StarterComponent() {
  const [spinner, setSpinner] = useState();

  useEffect(() => {
    let unsubscribe = false;
    const fetchSpinner = async () => {
      const res = await fetch(`${process.env.PUBLIC_URL}/livesign-loader.json`);
      const json = await res.json();

      if (unsubscribe) return;
      setSpinner(json);
    };

    fetchSpinner();

    return () => { unsubscribe = true; };
  }, []);

  return (
    <div className="app-loader">
      <div className="lottie-animation">
        <Lottie
          direction={1}
          options={{
            animationData: spinner,
            loop: true,
          }}
        />
      </div>
    </div>
  );
}
